import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Wrapper } from "@components/shared.styled";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import {
  Container,
  Content,
  Title,
  Description,
} from "./privacy-policy.styled";

const PrivacyPolicy = () => {
  const {
    markdownRemark: {
      frontmatter: { backgroundColor, largeHeader, description },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/privacy-policy/" }) {
          frontmatter {
            backgroundColor
            largeHeader
            description
          }
        }
      }
    `
  );

  return (
    <Container backgroundColor={backgroundColor}>
      <Wrapper>
        <Content>
          <Title>{largeHeader}</Title>
          <Description
            dangerouslySetInnerHTML={{
              __html: convertMarkdownToHtml(description),
            }}
          />
        </Content>
      </Wrapper>
    </Container>
  );
};

export default PrivacyPolicy;
