import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "@components/layout";
import PrivacyPolicy from "@modules/privacy-policy";

const PrivacyPolicyPage = () => {
  const {
    markdownRemark: {
      frontmatter: { pageName },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/privacy-policy/" }) {
          frontmatter {
            pageName
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Helmet>
        <title>{pageName}</title>
      </Helmet>
      <PrivacyPolicy />
    </Layout>
  );
};

export default PrivacyPolicyPage;
