import styled from "styled-components";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";
import { ClearfaceFont } from "@constants/fonts";

const Container = styled.section`
  background: ${({ containerBackground = Colors.white }) =>
    containerBackground};
  padding: 112px 0;
  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const Content = styled.div`
  max-width: 643px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-family: ${ClearfaceFont};
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 64px;
  @media (max-width: ${Screens.lg}) {
    font-size: 28px;
    margin: 0 0 40px;
  }
`;

const Description = styled.div`
  line-height: 1.4;
  p {
    margin: 0 0 26px;
  }
  h3 {
    font-size: 21px;
    margin: 32px 0 26px;
  }
`;

export { Container, Content, Title, Description };
